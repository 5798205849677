// TODO: allow plugin overrides
let populatePlugins = async () => {
  let plugins = [];
  let pluginPromises = [];
  let requireContext = require.context(
    "@/client/extensions/plugins",
    true,
    /\.(js)$/
  );

  requireContext.keys().forEach(async (filename) => {
    if (filename === "./index.js") {
      return true;
    }

    let result = requireContext(filename).default;

    if (typeof result === "object" && typeof result.then === "function") {
      pluginPromises.push(result);
    } else {
      plugins.push(result);
    }
  });

  let asyncPlugins = await Promise.allSettled(pluginPromises);

  asyncPlugins.forEach((plugin) => {
    plugins.push(plugin.value);
  });

  return plugins;
};

export default async () => {
  let plugins = await populatePlugins();

  return {
    install(instance) {
      plugins.forEach((plugin) => {
        instance.use(plugin);
      });
    },
  };
};
