<template>
  <block class="admin-dashboard-view" :class="{ rtl: isLanguageRtl() }">
    <div
      class="container container--expand flex flex-between padding-l padding-xl-vertical flex-wrap">
      <div class="title-wrapper margin-m-inline-end relative">
        <h3 class="margin-remove flex flex-middle">
          <div
            v-if="showTitleBackLink"
            class="title-back-icon-wrap margin-s-inline-end font-inverse background-lead"
            v-tooltip="translate('core.goBack')"
            style="border-radius: 100%; padding: 2px; cursor: pointer"
            @click="goBack()">
            <icon class="title-back-icon" theme="bg" icon="chevron-inline-start"></icon>
          </div>
          <div class="title-wrapper">
            <slot name="title">
              {{
                title
                  ? translateTitleCase(title)
                  : translateTitleCase("admin.dashboard.defaultTitle")
              }}
            </slot>
          </div>
        </h3>
      </div>

      <div class="breadcrumbs-wrapper flex flex-middle">
        <slot name="breadcrumbs">
          <breadcrumbs></breadcrumbs>
        </slot>
      </div>
    </div>

    <div class="container container--expand">
      <slot name="mainContent">
        <card class="">
          <div v-if="showToolbar" class="admin-view-toolbar">
            <!-- using slot toolbar overwrites the whole thing. consumer can also just use toolbarStart /end to use our premade layout-->
            <slot name="toolbar">
              <block class="actions uk-flex uk-flex-between">
                <block class="actions__start uk-flex">
                  <slot name="toolbarStart"></slot>
                </block>
                <block class="actions__end uk-flex">
                  <slot name="toolbarEnd"></slot>
                </block>
              </block>
            </slot>
          </div>

          <div class="uk-card-body">
            <slot name="default"></slot>
          </div>
        </card>
      </slot>
    </div>
  </block>
</template>

<script>
import { computed, watchEffect } from "vue";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  props: {
    showTitleBackLink: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    backRoute: {
      type: [Object, Boolean],
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup() {
    let { t } = useI18n();
    let { meta } = useMeta({
      sitename: {
        tag: "meta",
        name: "sitename",
        content: t("core.meta.adminSiteName"),
      },
    });

    return { meta };
  },
  data: function () {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.back();
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-content-wrapper {
  padding: var(--margin-xl) var(--margin-2xl);
}

.admin-view-toolbar {
  padding-top: var(--margin-l);
  padding-left: var(--margin-l);
  padding-right: var(--margin-l);
}

.title-back-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5em;
  width: 1.5em;
}
</style>
