<template>
  <div class="boxy-layout">
    <div class="boxy__outer">
      <div class="background-image" :style="boxyOuterStyle"></div>
      <div class="shader"></div>

      <div class="boxy__inner background-default overflow-auto">
        <card class="boxy__card">
          <slot></slot>
        </card>
      </div>

      <!--

                      <div class="uk-card-title">
                          <slot name="title">Test</slot>
                      </div>
                      <div class="uk-card-body">
                          <slot></slot>
                      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultComponent: String,
    backgroundImage: {
      type: String,
      default: "core/images/boxy/default-background.webp",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    boxyOuterStyle() {

      let gradiantBg = {
        background:
          "linear-gradient(45deg, var(--c-gray-5) 0%, var(--c-gray-5) 5%,transparent 5%, transparent 10%, var(--c-gray-5) 10%, var(--c-gray-5) 15%,transparent 15%,transparent 20%, var(--c-gray-5) 20%, var(--c-gray-5) 25%,transparent 25%,transparent 30%, var(--c-gray-5) 30%, var(--c-gray-5) 35%,transparent 35%,transparent 40%, var(--c-gray-5) 40%, var(--c-gray-5) 45%,transparent 45%,transparent 50%, var(--c-gray-5) 50%, var(--c-gray-5) 55%,transparent 55%,transparent 60%, var(--c-gray-5) 60%, var(--c-gray-5) 65%,transparent 65%,transparent 70%, var(--c-gray-5) 70%, var(--c-gray-5) 75%,transparent 70%,transparent 80%, var(--c-gray-5) 80%, var(--c-gray-5) 85%,transparent 85%,transparent 90%, var(--c-gray-5) 90%, var(--c-gray-5) 95%,transparent 95%)",
        "background-size": "3em 3em",
        "background-color": "var(--c-gray-1)",
        opacity: "0.1",
      };
      if ( ! this.backgroundImage) {
        return gradiantBg;
      }

      let url = utilities.requireAsset(this.backgroundImage);
      return {
        "background-image": "url('" + url + "')",
        filter: "blur(2px)",
        "-webkit-filter": "blur(2px)",
      };

    },
  },
};
</script>

<style lang="scss" scoped>
.boxy-layout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.shader,
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.background-image {
  // filter: blur(2px);
  //  -webkit-filter: blur(2px);
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
}

.shader {
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.boxy__outer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 100%;
  overflow: hidden;
  z-index: 4;
}

.boxy__inner {
  position: relative;
  z-index: 4;
  max-height: 90vh;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.36);
}

.boxy__card {
  @media (max-width: 640px) {
    padding: 20px 15px;
  }
}
</style>
