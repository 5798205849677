import { computed, reactive, ref, watchEffect, nextTick, watch } from "vue";

let getAsyncDataRulesUid = (input) => {
  //let valueUid = serialize(input).replace(/\s/g, "");
  let valueUid = JSON.stringify(input);

  if (utilities.isSSR()) {
    return new Buffer.from(valueUid).toString("base64");
  }

  return btoa(valueUid);
};

export default {
  install(app) {
    let debounceTimeout;
    let debounceDelay = 200;
   // let
    app.config.globalProperties.serverPrefetch = function () {
      /// this doesnt work, unfortunetly
    };
    app.mixin({
      watch: {},
      updated() {},
      created() {
        if (this.hasAsyncData()) {

          try {
            this.populateAsyncDataAsGetters();
            this.watchAsyncDataRequirements();
          } catch (e) {
            console.log(e);
          }

          if (app.isSaffronHydrating) {
            return;
          }
        }
      },
      mounted() {},
      methods: {
        refreshAsyncDataIfRequired: function () {
          this.refreshAsyncData();
        },
        refreshAsyncData: async function (keys = null) {

          // try to wait for initial user fetch, so the data will have user auth info
          let isInitialFetchComplete = this.$store.getters["user/initialFetchComplete"];
          if (!isInitialFetchComplete) {
            let userFetchPromise = new Promise((fulfil) => {
              watchEffect(() =>
                isInitialFetchComplete ? fulfil("fetchComplete") : null
              );
            });

            await utilities.promiseWithTimeLimit(userFetchPromise, 500).catch(() => {});
          }

          if (!this.asyncOps) {
            debug(
              "Warning - you set up async data, but did not provide asyncOps dependency in install (). Operation has been halted to prevent fatal error.",
              2,
              { component: this }
            );
            return;
          }

          if (typeof keys !== "object" || keys == null) {
            keys = null;
          }

          if (!this.asyncData) {
            return;
          }
          /* this debounce prevents some redundant requests, but it also causes problemts
          clearTimeout(debounceTimeout);
          debounceTimeout = utilities.setClientTimeout(async () =>
          {
            let result = await this.asyncOps.fetchAsyncData(this.asyncData, this, keys);
            this.$nextTick(this.updateMetaByState);
          }, 1);
          */
          let result = await this.asyncOps.fetchAsyncData(this.asyncData, this, keys);
          this.$nextTick(this.updateMetaByState);
          return result;
          },
        watchAsyncDataRequirements: function () {
          // this watches any changes to async data

          this.$watch(
            "asyncData",
            function (newValue, oldValue) {
              if (app.isSaffronHydrating) {
                return;
              }

              this.refreshAsyncDataIfRequired();
            },
            { deep: true, immediate: true }
          );

          // inside asyncData, there may be keys, where the data to be sent with the request is a funciton, subscribe to it
          for (const [key, value] of Object.entries(this.asyncData)) {
            let watchable = false;
            // in case value is string, it does not have nested data, move along
            if (typeof value !== "object") {
              continue;
            }

            // value of data is not a function, it is not dynamic. move along
            if (typeof value.data !== "function") {
              continue;
            }
            //    console.log(value.data);
          }
          // language changes require reloading
          this.refreshAsyncData();

          let lastLocale = this.$store.getters["locale/slug"];
          watchEffect(() => {
            if (this.$store.getters["locale/slug"] !== lastLocale) {
              lastLocale = this.$store.getters["locale/slug"];
              this.refreshAsyncDataIfRequired();
            }
          });
        },
        hasAsyncData: function () {
          // TODO: this triggers  vue warn in ssr
          if (!this.$data) {
            return false;
          }

          if (!this.$data.asyncData) {
            return false;
          }

          if (
            typeof this.$data.asyncData === "object" &&
            Object.keys(this.$data.asyncData).length < 1
          ) {
            return false;
          }

          if (!this.asyncOps) {
            warn(
              "warning: async data attribute detected, but no asyncOps is returned from setup. Ignoring your async data to avoid exceptions",
              {
                asyncData: this.$data.asyncData,
                name: this.name,
                component: this,
              }
            );
            return false;
          }

          return true;
        },
        populateAsyncDataAsGetters: function () {
          if (!this.hasAsyncData()) {
            return true;
          }

          for (const [key, value] of Object.entries(this.asyncData)) {
            let vuid = getAsyncDataRulesUid(value);
            let storeKey;
            let hasUUID = value && typeof value === "object" && value.uuid;

            if (hasUUID && (utilities.isSSR() || app.isSaffronHydrating)) {
              storeKey = value.uuid;
            } else {
              storeKey = `${vuid}-${key}`;
            }


            // TODO: if the asyncData object has a uuid, use that instead of this vuid.
            // this is important particularly for clever stuff like dates and functions
            let payload = {
              key: storeKey,
              value: this[key],
            };

            if (typeof this.asyncData[key] !== "object") {
              this.asyncData[key] = {
                target: this.asyncData[key],
              };
            }

            // if assignment has failed, and our key is still not an object, just return teh data
            if (typeof this.asyncData[key] !== "object") {
              this[key] = payload;
              continue;
            }

            this.asyncData[key].storeKey = storeKey;

            let all = this.$store.getters["asyncData/all"];
            //   console.log('log store in async data. all, and all[key]',all, all[storeKey]);

            if (
              app.isSaffronHydrating &&
              typeof this.$store.getters["asyncData/generic"](storeKey) !== "undefined"
            ) {
            } else {
              this.$store.commit("asyncData/generic", payload);
            }

            this[key] = computed({
              get: () => {
                return this.$store.getters["asyncData/generic"](storeKey);
              },
              set: (value) => {
                this.$store.commit("asyncData/generic", {
                  key: storeKey,
                  value,
                });
              },
            });
          }
        },
        /**
         * Basic method to await asyncOperations asyncData fetching
         * @returns {Promise<any>}
         */
        getSSRPrefetchPromise: function () {
          return new Promise((fulfil) => {
            // wait one tick at least
            nextTick(() => {
              // if we shoudnt wait, have not asyncOps, or are not loading data - we are good to go
              if (
                !config.useSSR ||
                !this.asyncOps ||
                !this.asyncOps.asyncStatus.asyncDataLoading
              ) {
                fulfil();
                return;
              }

              // wait - watch for when the data is ready
              watchEffect(() => {
                // watch asyndDataCleared
                if (this.asyncOps.asyncStatus.asyncDataClear) {
                  fulfil();
                }
              });
            });
          });
        },
        updateMetaByState: function () {},
      },
      serverPrefetch() {
        //   console.log('server prefetch mixin');return this.getSSRPrefetchPromise()
      },
    });
  },
};
