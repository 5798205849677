import { createMetaManager } from "vue-meta";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";
import { computed, ref, watchEffect } from "vue";

export default {
  install(app) {
    let manager = createMetaManager({ isSSR: utilities.isSSR() });

    app.use(manager);

    app.mixin({
      setup(a, b, c) {},
      updated() {},
      created() {
        let generateUseMeta = (args) => {
          let extractMetaObjectFromArgs = () => {
            if (typeof args === "string") {
              args = { title: args };
            }

            if (!args || typeof args !== "object") {
              args = {};
            }

            let translatedArgs = {};
            for (const [key, value] of Object.entries(args)) {
              if (typeof value === "string") {
                translatedArgs[key] = this.t(args[key]);
              } else {
                translatedArgs[key] = args[key];
              }
            }
            return {
              ...translatedArgs,
            };
          };

          let metaArgs = extractMetaObjectFromArgs();

          let meta = useMeta(metaArgs).meta;

          // watch locale and change meta - it is not properly reactive on it's own
          watchEffect(() => {
            let newMetaArgs = extractMetaObjectFromArgs();
            for (const [key, value] of Object.entries(newMetaArgs)) {
              meta[key] = value;
            }
          });

          return meta;
        };

        // apply meta tags automatcally, if assigned
        let accessor = this[config.metaDataAccessorKey];

        if (!accessor) {
          return;
        }

        let targetMeta = accessor();
        let i18n = useI18n();

        // case string - is page title
        if (typeof targetMeta === "string") {
          this.meta = generateUseMeta({ title: targetMeta });
          return this.meta;
        }

        if (typeof targetMeta === "function") {
          let finalMeta = targetMeta({ instance: this, useMeta, i18n });
          this.meta = useMeta(finalMeta).meta;
          return this.meta;
        }

        // case not object - we cant handle
        if (typeof targetMeta !== "object") {
          this.meta = useMeta({ }).meta;
          return this.meta;
        }

        // meta is object - create an instance, who is watched, for translations on locale change
        let finalMetaConf = { ...targetMeta };
        this.meta = generateUseMeta(finalMetaConf);
        return this.meta;
      },
      mounted() {},
    });
  },
};
