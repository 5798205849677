let availableLayouts = {};
let appName = process.env.VUE_APP_APPLICATION_NAME;
let context;

/**
 * Load layouts in the following priority: app overrides, app, core overrides, core.
 * Places them in availableLayouts, with modified key names to distinguish from "normal" components
 */
let populateLayouts = () => {
  // priority #1: app layout overrides
  //context = require.context('@/overrides/client/applications/', true, /^\.\/.*\/layouts\/.*\.vue$/);
  context = require.context(
    "@/",
    true,
    /overrides\/client\/applications\/.*\/layouts\/.*\.vue/
  );

  context.keys().forEach((key) => {
    let componentName = key.split("/").pop().replace(".vue", "");

    componentName =
      "Layout" + componentName.charAt(0).toUpperCase() + componentName.slice(1);

    if (availableLayouts.hasOwnProperty(componentName)) {
      return true;
    }

    availableLayouts[componentName] = context(key).default;
  });

  // priority #2: app layouts
  context = require.context("@/client/applications/", true, /^\.\/.*\/layouts\/.*\.vue$/);
  context.keys().forEach((key) => {
    let componentName = key.split("/").pop().replace(".vue", "");

    componentName =
      "Layout" + componentName.charAt(0).toUpperCase() + componentName.slice(1);
    if (availableLayouts.hasOwnProperty(componentName)) {
      return true;
    }
    availableLayouts[componentName] = context(key).default;
  });

  // priority #3: core layout overrides
  //  context = require.context("@/overrides/client/layouts",  true, /\.(vue)$/);
  context = require.context("@/", true, /\/overrides\/client\/layouts\/.*\.vue/);

  //   context = require.context("@/overrides/client/layouts",  true, /\.(vue)$/);

  context.keys().forEach((filename) => {
    let componentName;

    componentName = filename.replace("./", "").replace(".vue", "");
    componentName =
      "Layout" + componentName.charAt(0).toUpperCase() + componentName.slice(1);

    if (availableLayouts.hasOwnProperty(componentName)) {
      return true;
    }

    availableLayouts[componentName] = context(filename).default;
  });

  // priority #4: core layouts
  context = require.context("@/client/layouts", true, /\.(vue)$/);
  context.keys().forEach((filename) => {
    let componentName;

    componentName = filename.replace("./", "").replace(".vue", "");
    componentName =
      "Layout" + componentName.charAt(0).toUpperCase() + componentName.slice(1);

    if (availableLayouts.hasOwnProperty(componentName)) {
      return true;
    }

    availableLayouts[componentName] = context(filename).default;
  });
};

export default new Promise(async (resolve) => {
  await populateLayouts();

  let plugin = {
    install(app) {
      for (const [key, component] of Object.entries(availableLayouts)) {
        app.component(key, component);
      }
    },
  };
  resolve(plugin);
});
